<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-5 pl-5' : 'px-8'">
    
    <div class="mt-5">
      <!-- <Breadcrumbs :title="`Edit User`" :items="items"/> -->
      <Snackbar ref="snackbar"/>

      <v-btn href="javascript:history.back()"
        class="text-capitalize pl-0"
        elevation="0"
        text
        small
        color="#F05326">
        <v-icon left>mdi-chevron-left</v-icon>
        kembali
      </v-btn>
      <div class="title black--text mb-2">Edit User</div>

      <div>
        <ValidationObserver ref="observer">
          <v-form>
            <div class="mt-2 mb-3 body-1">About User</div>
            <div class="mb-5 mt-4">
              <v-card
                flat
                width="200"
                class="pa-0 mt-7"
                :style="form.picture !==''? 'background:transparent;':'background: #7575756b;'">
                <!-- <div>
                  <p class="mb-2 mt-2">Gambar Kategori</p>
                </div> -->
                <!-- ==== INPUT GAMBAR ==== -->
                <image-input v-model="avatar" type="uploadCover">
                  <div slot="activator">
                    <v-avatar 
                      tile 
                      size="200" 
                      height="200" 
                      class="grey lighten-3 cursor-pointer" 
                      v-ripple>
                      <v-img v-if="!avatar && addImage === ''"
                        :src="form.picture" 
                        width="200"
                        height="200">
                        <v-icon v-if="!avatar && addImage === ''" color="grey darken-3" large>mdi-camera</v-icon>
                      </v-img>
                      <div class="px-5" v-if="addImage && avatar.url === undefined">
                        <v-progress-linear
                          color="#d31145"
                          indeterminate
                          rounded
                          height="6"
                          class="mx-4 mt-4"
                          style="width: 150px;">
                        </v-progress-linear>
                        <p class="mt-2 mx-4 mb-0" style="font-size: 11px;">
                          Mengupload Gambar . . .
                        </p>
                      </div>
                      <img v-if="avatar && !addImage"
                        :src="form.picture" 
                        :alt="form.picture" 
                        width="200"
                        height="200"/>
                    </v-avatar>
                  </div>
                </image-input>
                <v-icon 
                  color="#424242" 
                  v-if="avatar && !addImage"
                  style="position: absolute; top: 5px; right: 3px;" 
                  @click="addImage = ''; avatar= null; form.picture = 'https://storage.googleapis.com/yec-cdn/5fad2ff6d9c15a03ece5272a/images/1627713462678523608-profile_picture.png';">
                  mdi-close-circle
                </v-icon>
              </v-card>
            </div>
            <!-- <v-img :src="require('@/assets/img/profile_picture.png')" width="200" class="px-0 pt-0 mb-3"></v-img> -->
            <div>
              <ValidationProvider name="Role" rules="required" v-slot="{ errors }">
                <v-text-field
                  outlined
                  dense
                  v-model="form.student_role"
                  label="Role"
                  autocomplete="off"
                  :error-messages="errors"
                  color="#F05326">
                </v-text-field>
              </ValidationProvider>
              <ValidationProvider name="Username" rules="required" v-slot="{ errors }">
                <v-text-field
                  outlined
                  dense
                  v-model="form.username"
                  :error-messages="errors"
                  label="Username"
                  autocomplete="off"
                  color="#F05326">
                </v-text-field>
              </ValidationProvider>
              <ValidationProvider name="Child Name" rules="required" v-slot="{ errors }">
                <v-text-field
                  outlined
                  dense
                  v-model="form.child_name"
                  :error-messages="errors"
                  label="Child Name"
                  autocomplete="off"
                  color="#F05326">
                </v-text-field>
              </ValidationProvider>
              <ValidationProvider name="Age" rules="required" v-slot="{ errors }">
                <v-text-field
                  outlined
                  dense
                  type="number"
                  v-model="form.age"
                  :error-messages="errors"
                  label="Age"
                  autocomplete="off"
                  color="#F05326">
                </v-text-field>
              </ValidationProvider>
              <ValidationProvider name="Gender" rules="required" v-slot="{ errors }">
                <v-text-field
                  outlined
                  dense
                  v-model="form.gender"
                  :error-messages="errors"
                  label="Gender"
                  autocomplete="off"
                  color="#F05326">
                </v-text-field>
              </ValidationProvider>
              <ValidationProvider name="Parent`s Name" rules="required" v-slot="{ errors }">
                <v-text-field
                  outlined
                  dense
                  v-model="form.parent_name"
                  :error-messages="errors"
                  label="Parent`s Name"
                  autocomplete="off"
                  color="#F05326">
                </v-text-field>
              </ValidationProvider>
            </div>

            <div class="mt-2 mb-3 body-1">Contact Info</div>
            <div>
              <ValidationProvider name="Whatsapp Number" rules="required|max:15" v-slot="{ errors }">
                <v-text-field
                  outlined
                  dense
                  v-model="form.phone"
                  :error-messages="errors"
                  label="Whatsapp Number"
                  autocomplete="off"
                  color="#F05326">
                </v-text-field>
              </ValidationProvider>
              <ValidationProvider name="Email Address" rules="required|email" v-slot="{ errors }">
                <v-text-field
                  outlined
                  dense
                  v-model="form.email"
                  :error-messages="errors"
                  label="Email Address"
                  autocomplete="off"
                  color="#F05326">
                </v-text-field>
              </ValidationProvider>
            </div>

            <!-- <div class="mt-2 mb-3 body-1">Learning Info</div> -->
            <!-- <div>
              <v-card
                class="mx-auto"
                outlined>
                <v-list-item class="text-center">
                  <v-list-item-content>
                    <v-list-item-title> Last Access </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title> Topic / Week </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title> Activity </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title> Score </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item  class="text-center" v-for="(item,index) in list" :key="index">
                  <v-list-item-content>
                    <v-list-item-title> {{ item.last_access | dateFull }} </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title> {{ item.topic }} </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title> {{ item.activity }} </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title> {{ item.score == "" ? " - " : item.score }} </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </div> -->

            <div class="mt-7 mb-15 float-right">
              <v-btn
                dense
                depressed
                :loading="$store.state.process.run"
                :disabled="$store.state.process.run"
                class="text-capitalize black--text mr-5"
                @click="$router.push('/progress')"
                color="#F5F5F5">
                Cancel
              </v-btn>
              <v-btn
                dense
                depressed
                :loading="$store.state.process.run"
                :disabled="$store.state.process.run"
                class="text-capitalize white--text"
                @click="save"
                color="#F05326">
                Save
              </v-btn>
            </div>
          </v-form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { get, post, put , destroy } from '@/service/Axios'
import ImageInput from '@/components/ImageInput';
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Snackbar from '@/components/Snackbar.vue'
export default {
  components: { ImageInput, Breadcrumbs, Snackbar },
  data() {
    return {
      items: [
        {
          text: 'Users List',
          disabled: false,
          href: '/progress',
        },
        {
          text: 'Edit User',
          disabled: true,
          href: '/users',
        }
      ],
      list: [
        {
          last_access: "2021-04-02",
          topic: "3",
          activity:"2",
          score: ""
        },
        {
          last_access: "2021-04-03",
          topic: "3",
          activity:"2",
          score: ""
        },
        {
          last_access: "2021-04-04",
          topic: "3",
          activity:"2",
          score: ""
        },
        {
          last_access: "2021-04-05",
          topic: "3",
          activity:"2",
          score: ""
        }
      ],
      avatar: null,
      addImage: "",
      process: {
        run: false
      },
      form: {
        id: "",
        picture: "https://storage.googleapis.com/yec-cdn/5fad2ff6d9c15a03ece5272a/images/1627713462678523608-profile_picture.png",
        student_role: "Student",
        parent_name: "",
        username: "",
        child_name: "",
        age: "",
        gender: "",
        email: "",
        phone: 0,
      }
    }
  },
  watch:{
    avatar: {
      handler: function () {
        this.form.picture = this.avatar.url
        this.addImage = this.avatar.add
      },
      deep: true
    }
  },
  computed:{},
  created(){
    this.fetch()
  },
  mounted(){},
  methods:{
    async fetch(){
      this.$store.state.process.run = true
      await get(`api/v1/member/detail/${this.$route.params.id}`)
      .then((response) => {
        let res = response.data
        if (res.status == 200) {
          this.avatar = res.data.picture != "" ? {
            url: res.data.picture
          } : null

          this.form = {
            id: res.data.id,
            picture: res.data.picture != "" ? res.data.picture : "https://storage.googleapis.com/yec-cdn/5fad2ff6d9c15a03ece5272a/images/1627713462678523608-profile_picture.png",
            student_role: "Student",
            parent_name: res.data.parent_name,
            username: res.data.username,
            child_name: res.data.child_name,
            age: res.data.age,
            gender: res.data.gender,
            email: res.data.email,
            phone: res.data.phone,
          }
          this.data = res.data
          this.$store.state.process.run = false
        }else{
          this.$store.state.process.run = false
          this.dialog.success = true
          this.dialog.color = "red"
          this.dialog.message = res.message
        }
      })
    },
    async save(){
      this.$store.state.process.run = true
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        await put(`api/v1/member/update/${this.$route.params.id}`, {
          data: {
            username: this.form.username,
            child_name: this.form.child_name,
            email: this.form.email,
            picture: this.form.picture,
            age: this.form.age,
            gender: this.form.gender,
            parent_name: this.form.parent_name,
            phone: this.form.phone
          }
        })
        .then((response) => {
          let res = response.data
          if (res.status == 200) {
            this.$store.state.process.run = false
            this.$refs.snackbar.open("#4CAF50", `User Updated Succesfully`);
            setTimeout(() => {
              this.$router.push(`/users`)
            }, 1000);
          }else{
            this.$store.state.process.run = false
            this.$refs.snackbar.open("error", `User Update Failed`);
          }
        })
      }else{
        this.$store.state.process.run = false
        this.$refs.snackbar.open("error", `User Update Failed`);
      }
    }
  }
}
</script>

<style>

</style>